<template>
  <div
    v-if="!isProductInPoints"
    class="v-product-card-price v-nomenclature-card-price v-flex-row v-product-card-gap v-align-items-center"
  >
    <template v-if="productInList.ShowTextInPriceButton">
      <span
        class="v-mr-xxs"
        v-html="translate('productCard.showTextInPriceButton')"
      />
    </template>
    <div :class="(menuStore.CurrentItemsInRow ?? 0) >= 5 ? 'v-d-flex v-flex-column-reverse' : ''">
      <span class="v-price">
        <common-currency
          v-if="
            productInList.PriceInPoints > 0 ||
            (menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ?? 0) > 0
          "
          class="v-align-currency-center"
          show-points
          :amount="
            productInList.PriceInPoints +
            (menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ?? 0)
          "
        />
        <common-currency
          v-else
          :amount="priceModified"
        />
      </span>
      <span
        v-if="priceModified !== price"
        class="v-price-s"
      >
        <common-currency
          v-if="
            productInList.PriceInPoints > 0 ||
            (menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ?? 0) > 0
          "
          class="v-align-currency-center"
          show-points
          :amount="
            productInList.PriceInPoints +
            (menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ?? 0)
          "
        />
        <common-currency
          v-else
          class="v-align-currency-center"
          strikethrough
          :amount="price"
        />
      </span>
    </div>
  </div>
  <menu-product-in-list-add-to-cart-button
    :card-click="cardClick"
    :class="{ 'v-flex-grow-1 v-product-in-points-btn': isProductInPoints }"
    :is-product-in-points="isProductInPoints"
    :is-recommendation="isRecommendation"
    :product-in-list="productInList"
    data-test-id="product-card-btn-buy"
  />
</template>

<script setup lang="ts">
import { ProductApiType } from '~api/consts'

import type { AnyProductCard } from '~types/props'

const props = defineProps<AnyProductCard>()

const { productInList } = toRefs(props)

const menuStore = useMenuStore()

const isProductInPoints = computed<boolean>(() => {
  return (
    productInList.value.PriceInPoints > 0 ||
    (menuStore.SelectedOptionsPerProduct.get(productInList.value.ID)?.PriceInPoints ?? 0) > 0
  )
})

const { translate } = useI18nSanitized()

const price = computed<number>(() =>
  productInList.value.ProductApiType === ProductApiType.Half ||
  productInList.value.ProductApiType === ProductApiType.Lunch ||
  productInList.value.ProductApiType === ProductApiType.Quarter
    ? productInList.value.CalcMinPrice +
      (menuStore.SelectedOptionsPerProduct.get(productInList.value.ID)?.CalcMinPrice ?? 0)
    : productInList.value.Price +
      (menuStore.SelectedOptionsPerProduct.get(productInList.value.ID)?.Price ?? 0)
)

const priceModified = computed<number>(() =>
  productInList.value.ProductApiType === ProductApiType.Half ||
  productInList.value.ProductApiType === ProductApiType.Lunch ||
  productInList.value.ProductApiType === ProductApiType.Quarter
    ? productInList.value.CalcMinPriceModified +
      (menuStore.SelectedOptionsPerProduct.get(productInList.value.ID)?.CalcMinPriceModified ?? 0)
    : productInList.value.PriceModified +
      (menuStore.SelectedOptionsPerProduct.get(productInList.value.ID)?.PriceModified ?? 0)
)
</script>
